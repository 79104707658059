import React, { ReactNode } from 'react';
import ContentContainer from '../components/ContentContainer';
import useSiteMetadata from '../hooks/useSiteMetadata';
import { Layout } from '../components';
import './about.scss';

export default function AboutPage(): ReactNode {
  const { title: siteTitle } = useSiteMetadata();

  return (
    <Layout title={siteTitle}>
      <ContentContainer>
        <h1 className="about__title">About Me</h1>
        <p>
          I am currently a full-time software engineer based in Singapore.
          Passionate in all things about software development,
          especially in web application development.
        </p>
        <p>
          Building softwares and troubleshooting applications are my daily breads.
          Clean codes and interesting software architectures are music to my ears.
        </p>
        <p>Working towards becoming a Solution Architect one day! Until then, grind on!</p>
        <h2>Education</h2>
        <p>
          Graduated from University of Science, Malaysia
          with a Bachelor of  Computer Science (Honours) back in year 2017.
        </p>
      </ContentContainer>
    </Layout>
  );
}
